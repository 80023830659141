<template>
  <div class="new-contacts">
    <div class="contacts-map"><div id="map" style="width: 100%; height: 100%"></div></div>
    <div class="contacts">
      <go-back-btn/>
      <h1>Контакты</h1>
      <div class="contacts__wrapper">
        <div class="contacts__item">
          <h4>Основной телефон</h4>
          <a href="tel:+74952801123">+7 (495) 280-11-23</a>
        </div>
        <div class="contacts__item">
          <h4>Отдел продаж</h4>
          <a href="tel:+74993253020">+7 (499) 325-30-20</a>
        </div>
        <div class="contacts__item">
          <h4>E-mail</h4>
          <a href="mailto:kck@kck.ru">kck@kck.ru</a>
        </div>
      </div>
      <div class="contacts__details-wrapper">
        <div class="contacts__details-item">
          <img
            src="@/assets/img/icons/kck-logo.svg"
            alt="KCK logo"
          >
          <span>ООО "КСК ТЕХНОЛОГИИ"<br>ИНН 7718064797</span>
        </div>
        <div class="contacts__details-item">
          <img
            src="@/assets/img/icons/geo.svg"
            alt="Геометка"
          >
          <span>Россия, 107076, Москва,<br>ул. Краснобогатырская, д. 89 стр.1</span>
        </div>
      </div>
      <div class="grid dropped-block">
        <div class="top-border">&nbsp;</div>
        <h3>Как добраться</h3>
        <p>Наш офис находится на территории БЦ "Мосрентсервис"
          в шаговой доступности от станции метро Преображенская площадь
          (выход №1 – первый вагон из центра). На городском транспорте
          также можно добраться от станции метро
          Семеновская: автобус № 311, трамваи № 2, 11, 46 до остановки Улица Хромова.</p>
        <img
          src="@/assets/img/about-page/cont_img.png"
          alt=""
        >
        <div class="bottom-border">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
import GoBackBtn from '@/components/common/GoBackBtn.vue';

export default {
  name: 'ContactsPage',

  components: {
    GoBackBtn,
  },

  mounted() {
    const mapScript = document.createElement('script');
    mapScript.setAttribute('src', 'https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A4c0389841027dcbba08b2fb5976cd0010f9405951845dd332d58daf4f3dbf1a4&amp;width=100%25&amp;height=1130&amp;lang=ru_RU&amp;scroll=true');
    document.getElementById('map').appendChild(mapScript);
  },
};
</script>
