<template>
  <div>
    <div class="main-page">
      <STPageContent/>
      <STPageVisual/>
    </div>
  </div>
</template>

<script>
import STPageContent from '@/components/product-page-st-parts/STPageContent.vue';
import STPageVisual from '@/components/product-page-st-parts/STPageVisual.vue';

export default {
  name: 'ProductPageST',
  components: {
    STPageVisual,
    STPageContent,
  },
};
</script>
