<template>
  <div class="main-page-content">
    <div class="main-page-content__inner">
      <go-back-btn
        class="go-back-product-page"
      />
      <div class="main-page-content__mobile-header">
        <div>
          <img
            src="@/assets/img/products/triangle-sd.svg"
            alt=""
          >
        </div>
        <div>КСК.Service Desk</div>
      </div>
      <h1>КСК.Service Desk (KCK.SD) – линейка решений класса ITSM для управления ИТ- и
        бизнес-услугами для качественной поддержки по принципу единого окна.</h1>
      <div class="mobile-btn">
        <a class="btn-try-for-free" href="/sd/#/?backPath=/sd">
          <button class="btn btn--common">
            Попробовать бесплатно
          </button>
        </a>
      </div>
      <div class="main-text">
        Обратная связь – важная часть эффективного функционирования любой компании.
        Будьте в круглосуточном контакте со своими пользователями с помощью КСК.Service Desk.
      </div>
      <h3>Комплексное решение для управления любыми сервисами компании:</h3>
      <div class="main-page-advantages-mobile">
        <common-spoiler
          title="Управление услугами"
          content="создание любых услуг с
            возможностью классификации по различным признакам, разграничением прав доступа,
            настройка параметров предоставления (SLA)"
        >
          <img src="@/assets/img/products/sd/advantages/Tick.png" alt="">
        </common-spoiler>
        <common-spoiler
          title="Управление активами и конфигурациями (КЕ)"
          content="индивидуальная настройка
            детальной информации в зависимости от типа КЕ и распределение ответственности по
            сопровождению"
        >
          <img src="@/assets/img/products/sd/advantages/Chart.png" alt="">
        </common-spoiler>
        <common-spoiler
          title="Портал системы"
          content="единое пространство для подачи, отслеживания
            и исполнения заявок, доступ 24/7"
        >
          <img src="@/assets/img/products/sd/advantages/Info.png" alt="">
        </common-spoiler>
        <common-spoiler
          title="Аналитика"
          content="полный контроль по исполнению заявок и
            жизненному циклу активов, отчетность в удобном формате"
        >
          <img src="@/assets/img/products/st/advantages/Graph.png" alt="">
        </common-spoiler>
      </div>
      <div class="main-page-advantages main-page-advantages_sd">
        <div class="main-page-advantage">
          <img src="@/assets/img/products/sd/advantages/Tick.png" alt="Галка">
          <span>
            <strong>Управление услугами</strong> — создание любых услуг с
            возможностью классификации по различным признакам, разграничением прав доступа,
            настройка параметров предоставления (SLA).
          </span>
        </div>
        <div class="main-page-advantage">
          <img src="@/assets/img/products/sd/advantages/Chart.png" alt="График">
          <span>
            <strong>Управление активами и конфигурациями (КЕ)</strong> — индивидуальная настройка
            детальной информации в зависимости от типа КЕ и распределение ответственности по
            сопровождению.
          </span>
        </div>
        <div class="main-page-advantage">
          <img src="@/assets/img/products/sd/advantages/Info.png" alt="Инфо">
          <span>
            <strong>Портал системы</strong> — единое пространство для подачи, отслеживания
            и исполнения заявок, доступ 24/7.
          </span>
        </div>
        <div class="main-page-advantage">
          <img src="@/assets/img/products/st/advantages/Graph.png" alt="График">
          <span>
            <strong>Аналитика</strong> — полный контроль по исполнению заявок и
            жизненному циклу активов, отчетность в удобном формате.
          </span>
        </div>
      </div>
      <div class="main-page-buttons">
        <a class="btn-about" href="https://www.kck.ru/products/kck-service-desk" target="_blank">
          <button class="btn btn--common">
            Узнать больше
          </button>
        </a>
        <a class="btn-try-for-free" :href="getUrl('/sd/#/?backPath=/sd')">
          <button class="btn btn--common">
            Попробовать бесплатно
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import GoBackBtn from '@/components/common/GoBackBtn.vue';
import CommonSpoiler from '@/components/common/CommonSpoiler.vue';
import redirectMixin from '@/mixins/redirectMixin';

export default {
  name: 'SDPageContent',

  components: { GoBackBtn, CommonSpoiler },

  mixins: [
    redirectMixin,
  ],
};
</script>
