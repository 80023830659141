var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-bar--item top-bar--item-locale"},[_c('div',{staticClass:"locale"},[_c('div',{staticClass:"locale__btn"},[_c('button',{staticClass:"locale__btn-globe pe-0",attrs:{"title":_vm.getGlobeTitle},on:{"click":_vm.changeLocale}},[_c('i',{staticClass:"btn-icon icon_size_l icon-globe"})]),_c('button',{staticClass:"locale__btn-arrow ps-0",on:{"click":_vm.showSelectors}},[_c('i',{staticClass:"btn-icon icon_size_xs icon-caret-down"})])]),(_vm.isSelectorsShown)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeSelectors),expression:"closeSelectors"}],staticClass:"locale__body submenu"},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"frm-label"},[_vm._v(" "+_vm._s(_vm.getMessageByKey('locale.language'))+" ")]),_c('selector',{attrs:{"params":{
              placeHolderTitle: _vm.getMessageByKey('local.language.placeholder'),
              inputDeleteIcon: false,
              droplistOverlay: true,
              droplistAdaptive: true,
            },"values":_vm.selectedParameter['language'],"handbook":_vm.handbookData['language']},on:{"newValues":_vm.onLanguageSelect}})],1),_c('div',[_c('div',{staticClass:"frm-label"},[_vm._v(" "+_vm._s(_vm.getMessageByKey('locale.region'))+" ")]),_c('selector',{attrs:{"params":{
              placeHolderTitle: _vm.getMessageByKey('local.region.placeholder'),
              inputDeleteIcon: false,
              droplistOverlay: true,
              droplistAdaptive: true,
            },"values":_vm.selectedParameter['region'],"handbook":_vm.handbookData['region']},on:{"newValues":_vm.onRegionSelect}})],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }