<template>
  <div>
    <div class="main-page">
      <SDPageContent/>
      <SDPageVisual/>
    </div>
  </div>
</template>

<script>
import SDPageContent from '@/components/product-page-sd-parts/SDPageContent.vue';
import SDPageVisual from '@/components/product-page-sd-parts/SDPageVisual.vue';

export default {
  name: 'ProductPageSD',
  components: {
    SDPageVisual,
    SDPageContent,
  },
};
</script>
