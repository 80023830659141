<template>
  <div class="main-page-visual">
    <div class="main-page-visual__inner main-page-visual__inner_sd">
      <img src="@/assets/img/products/sd/visual/sd-main.png" alt="KCK.SD">
    </div>
  </div>
</template>

<script>
export default {
  name: 'SDPageVisual',
};
</script>
