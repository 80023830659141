<template>
  <div class="main-page-content">
    <div class="main-page-content__inner">
      <go-back-btn
        class="go-back-product-page"
      />
      <div class="main-page-content__mobile-header">
        <div>
          <img
            src="@/assets/img/products/triangle-st.svg"
            alt=""
          >
        </div>
        <div>КСК.Service&Teamwork</div>
      </div>
      <h1>KCK.Service&Teamwork — облачное решение для организации работы
        и коммуникации команды как в офисе, так и в режиме удаленной работы</h1>
      <div class="mobile-btn">
        <a class="btn-try-for-free" href="/st/#/?backPath=/st">
          <button class="btn btn--common">
            Попробовать бесплатно
          </button>
        </a>
      </div>
      <div class="main-text">
        Работа любой команды становится эффективнее, когда она сконцентрирована в едином
        пространстве. Предоставьте вашим сотрудникам удобные инструменты для организации
        любых процессов и коммуникации с помощью КСК.Service&Teamwork.
      </div>
      <h3>Все, что нужно для эффективной работы команды:</h3>
      <div class="main-page-advantages-mobile">
        <common-spoiler
          title="Teamwork"
          content="лучшее решение для управления задачами и проектами"
        >
          <img src="@/assets/img/products/st/advantages/Folder.png" alt="">
        </common-spoiler>
        <common-spoiler
          title="Service"
          content="предоставление любых сервисов и услуг внутри компании"
        >
          <img src="@/assets/img/products/st/advantages/Search.png" alt="">
        </common-spoiler>
        <common-spoiler
          title="Корпоративный портал"
          content="единое информационное поле для работы и общения"
        >
          <img src="@/assets/img/products/st/advantages/Chat.png" alt="">
        </common-spoiler>
        <common-spoiler
          title="Аналитика"
          content="полный контроль по исполнению задач
            сотрудниками и отчетность в удобном формате"
        >
          <img src="@/assets/img/products/st/advantages/Graph.png" alt="">
        </common-spoiler>
      </div>
      <div class="main-page-advantages">
        <div class="main-page-advantage">
          <img src="@/assets/img/products/st/advantages/Folder.png" alt="Папка">
          <span>
            <strong>Teamwork</strong> — лучшее решение для управления задачами и проектами
          </span>
        </div>
        <div class="main-page-advantage">
          <img src="@/assets/img/products/st/advantages/Search.png" alt="Поиск">
          <span>
            <strong>Service</strong> — предоставление любых сервисов и услуг внутри компании
          </span>
        </div>
        <div class="main-page-advantage">
          <img src="@/assets/img/products/st/advantages/Chat.png" alt="Чат">
          <span>
            <strong>Корпоративный портал</strong> — единое информационное поле для работы и общения
          </span>
        </div>
        <div class="main-page-advantage">
          <img src="@/assets/img/products/st/advantages/Graph.png" alt="График">
          <span>
            <strong>Аналитика</strong> — полный контроль по исполнению задач
            сотрудниками и отчетность в удобном формате
          </span>
        </div>
      </div>
      <div class="main-page-buttons">
        <a class="btn-about" href="https://www.kck.ru/products/kck-service-teamwork" target="_blank">
          <button class="btn btn--common">
            Узнать больше
          </button>
        </a>
        <a class="btn-try-for-free" :href="getUrl('/st/#/?backPath=/st')">
          <button class="btn btn--common">
            Попробовать бесплатно
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import GoBackBtn from '@/components/common/GoBackBtn.vue';
import CommonSpoiler from '@/components/common/CommonSpoiler.vue';
import redirectMixin from '@/mixins/redirectMixin';

export default {
  name: 'STPageContent',

  components: { CommonSpoiler, GoBackBtn },

  mixins: [
    redirectMixin,
  ],
};
</script>
