<template>
  <router-link to="/products" class="btn-go-to-start-page">
    <span aria-hidden="true" class="btn-icon icon-arrow-left"/>
    <span>Назад</span>
  </router-link>
</template>

<script>
export default {
  name: 'GoBackBtn',
};
</script>
