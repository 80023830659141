const axios = require('axios');

function getJsonFileByPath(path) {
  return new Promise((resolve, reject) => {
    const promise = axios.get(path);
    promise.then((result) => {
      // const data = result.data;
      resolve(result.data);
    })
      .catch((error) => {
        reject(error);
        throw error;
      });
  });
}

function getJsonFileByPathAllInfo(path) {
  return new Promise((resolve, reject) => {
    const promise = axios.get(path);
    promise.then((result) => {
      resolve(result);
    })
      .catch((error) => {
        reject(error);
        throw error;
      });
  });
}

export { getJsonFileByPath, getJsonFileByPathAllInfo };
