<template>
  <div class="main-page-visual">
    <div class="main-page-visual__inner">
      <img src="@/assets/img/products/projects/visual/projects-main.png" alt="KCK.Проекты">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectsPageVisual',
};
</script>
