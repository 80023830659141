<template>
  <div class="about">
    <div class="company-new">
      <div class="component-wrapper">
        <div class="custom">
          <section class="main-section new-products">
            <div class="container">
              <div class="grid">
                <div class="main-block">
                  <go-back-btn/>
                  <h1 class="main-title">
                    КСК ТЕХНОЛОГИИ — быть на одной высоте с нашим клиентом!
                  </h1>
                </div>
              </div>
            </div>
          </section>
          <section class="gradient-block fire-block">
            <div class="container">
              <div class="gradient-block-wrapper">
                <div class="gradients-bg">
                  <div class="top-border">&nbsp;</div>
                  <div class="img-puzzle fire">&nbsp;</div>
                  <div class="bottom-border">&nbsp;</div>
                  <p>Мы лидирующая компания в области создания платформ,
                    продуктов и заказных решений на базе открытого ПО.
                    Мы убеждены, что основа успеха КСК ТЕХНОЛОГИИ
                    — сплоченная команда специалистов,
                    позволяющая нам гордиться выполненными
                    проектами и быть уверенными в успешной реализации новых.</p>
                </div>
              </div>
            </div>
          </section>
          <section class="numbers-section">
            <div class="container">
              <h3>КСК ТЕХНОЛОГИИ в цифрах</h3>
              <div class="grid">
                <div class="main-block">
                  <figure
                    class="ellipse"
                    data-wf-figure="1"
                  ><img
                      src="@/assets/img/about-page/26.png"
                      alt="26+"
                    /></figure>
                  <p>лет на рынке разработки заказных решений</p>
                </div>
                <div class="main-block flex-end">
                  <figure
                    class="ellipse"
                    data-wf-figure="1"
                  ><img
                      src="@/assets/img/about-page/300.png"
                      alt="300+"
                    /></figure>
                  <p>проектов внедрений BPMS в государственных и коммерческих организациях</p>
                </div>
                <div class="main-block flex-end">
                  <figure
                    class="ellipse"
                    data-wf-figure="1"
                  ><img
                      src="@/assets/img/about-page/100.png"
                      alt="100+"
                    /></figure>
                  <p>высококвалифицированных профессионалов в штате</p>
                </div>
                <div class="main-block flex-end">
                  <figure
                    class="ellipse"
                    data-wf-figure="1"
                  ><img
                      src="@/assets/img/about-page/20.png"
                      alt="20+"
                    /></figure>
                  <p>уникальных отраслевых решений</p>
                </div>
              </div>
            </div>
          </section>
          <section class="gradient-block rocket-block">
            <div class="container">
              <div class="gradient-block-wrapper">
                <div class="gradients-bg">
                  <div class="top-border">&nbsp;</div>
                  <div class="img-puzzle rocket">&nbsp;</div>
                  <div class="bottom-border">&nbsp;</div>
                  <p>Мы постоянно осваиваем передовые
                    технологии, применяем
                    инновационные подходы, проводим инженерные исследования,
                    внедряем практики DevOps, используем микросервисную архитектуру,
                    разрабатываем высоконагруженные кластерные системы,
                    используем облачные технологии.
                    Мы амбициозны и не останавливаемся на достигнутом!</p>
                </div>
              </div>
            </div>
          </section>
          <section class="animation-block history">
            <div class="container">
              <h3>История компании</h3>
              <div class="animation-wrapper">
                <div class="tabs-block second-tabs clearfix">
                  <div class="second-wrapper-tabs">
                    <div class="second-tabs-wrapper">
                      <div class="second-btn-toogle">
                        <div class="tab-name">1996 – 2006</div>
                        <p>Заказная разработка на базе Lotus Notes</p>
                      </div>
                      <div class="second-btn-toogle">
                        <div class="tab-name">2006-2007</div>
                        <p>Высокая экспертиза по работе на портальных технологиях</p>
                      </div>
                      <div class="second-btn-toogle">
                        <div class="tab-name">2008</div>
                        <p>Cтарт работ по теме «Электронное правительство»</p>
                      </div>
                      <div class="second-btn-toogle">
                        <div class="tab-name">2009</div>
                        <p>Cоздание собственной low-code платформы КСК.Интеллектуальный конвейер</p>
                      </div>
                      <div class="second-btn-toogle">
                        <div class="tab-name">2011</div>
                        <p>Cоздание собственного интеграционного решения КСК.Шлюз СМЭВ</p>
                      </div>
                      <div class="second-btn-toogle">
                        <div class="tab-name">2014-2016</div>
                        <p>Разработка решений для управления строительством</p>
                      </div>
                      <div class="second-btn-toogle">
                        <div class="tab-name">2019</div>
                        <p>Создание продукта КСК.Service Desk на базе платформы КСК.ИК</p>
                      </div>
                      <div class="second-btn-toogle">
                        <div class="tab-name">2021-2022</div>
                        <p>Разработка собственного чат-бота</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="second-info-tabs-section-wrapper">
                  <section
                    class=""
                    style="display: none;"
                  ><img
                      src="@/assets/img/about-page/bg2200.png"
                      alt="go"
                    /></section>
                  <section
                    class=""
                    style="display: none;"
                  ><img
                      src="@/assets/img/about-page/bg2201.png"
                      alt="go"
                    /></section>
                  <section
                    class="active"
                    style="display: block;"
                  ><img
                      src="@/assets/img/about-page/bg2205.png"
                      alt="go"
                    /></section>
                  <section
                    class=""
                    style="display: none;"
                  ><img
                      src="@/assets/img/about-page/bg2208.png"
                      alt="go"
                    /></section>
                  <section
                    class=""
                    style="display: none;"
                  ><img
                      src="@/assets/img/about-page/bg2221.png"
                      alt="go"
                    /></section>
                  <section
                    class=""
                    style="display: none;"
                  ><img
                      src="@/assets/img/about-page/bg2224.png"
                      alt="go"
                    /></section>
                  <section
                    class=""
                    style="display: none;"
                  ><img
                      src="@/assets/img/about-page/bg2227.png"
                      alt="go"
                    /></section>
                  <section
                    class=""
                    style="display: none;"
                  ><img
                      src="@/assets/img/about-page/bg2229.png"
                      alt="go"
                    /></section>
                </div>
              </div>
            </div>
          </section>
          <section class="our-partners">
            <div class="container">
              <h2>Нам доверяют</h2>
              <div class="partner-wrapper">
                <div class="partner-slider">
                  <div class="slider resourses clearfix">
                    <div class="slider-container clearfix">
                      <div class="slide-list clearfix">
                        <ul class="slide-wrap col-md-12 clearfix">
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p1.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Федеральное агентство железнодорожного транспорта
                              </span>
                            </div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p2.svg"
                                alt="partner"
                              /> <span class="partner-title">Лукойл</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p3.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Федеральное агентство по рыболовству
                              </span>
                            </div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p4.svg"
                                alt="partner"
                              /> <span class="partner-title">Renault S.A. Франция</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p5.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Федеральное агентство водных ресурсов
                              </span>
                            </div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p6.svg"
                                alt="partner"
                              /> <span class="partner-title">ПАО "Ростелеком"</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p7.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Федеральное агентство по техническому регулированию и метрологии
                              </span>
                            </div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p8.svg"
                                alt="partner"
                              /> <span class="partner-title">ОАО «РЖД»</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p9.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Федеральная служба по регулированию алкогольного рынка
                              </span>
                            </div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p10.svg"
                                alt="partner"
                              /> <span class="partner-title">ООО "ТТК-Связь"</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p11.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Федеральная служба государственной статистики
                              </span>
                            </div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p12.svg"
                                alt="partner"
                              /> <span class="partner-title">Renault S.A. Франция</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p13.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Министерство здравоохранения
                              </span>
                            </div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p14.svg"
                                alt="partner"
                              /> <span class="partner-title">"Рольф"</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p15.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Министерство строительства и жилищно-коммунального хозяйства
                              </span>
                            </div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p16.svg"
                                alt="partner"
                              /> <span class="partner-title">Saint-Gobain Франция</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p17.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Министерство экономического развития
                              </span>
                            </div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p18.svg"
                                alt="partner"
                              /> <span class="partner-title">ОАО «ТНК-BP»</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p19.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Федеральное агентство по управлению особыми экономическими зонами
                              </span>
                            </div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p20.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Федеральная таможенная служба
                              </span>
                            </div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p21.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Министерство регионального развития
                              </span>
                            </div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p22.svg"
                                alt="partner"
                              /> <span class="partner-title">ОАО «Соллерс»</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p23.svg"
                                alt="partner"
                              /> <span class="partner-title">Danone</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p24.svg"
                                alt="partner"
                              /> <span class="partner-title">ФНКЦ им. Д. Рогачева</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p25.svg"
                                alt="partner"
                              /> <span class="partner-title">ОАО "Роснефть"</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p26.svg"
                                alt="partner"
                              /> <span class="partner-title">ФАУ ФЦС</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p27.svg"
                                alt="partner"
                              /> <span class="partner-title">РГГУ</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p28.svg"
                                alt="partner"
                              /> <span class="partner-title">Добровольная сертификация</span></div>
                          </li>
                          <li class="slide-item">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/p29.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Лондонский метрополитен Великобритания
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <button class="navy next-slide"></button>
                      <button class="navy prev-slide"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="our-partners licenses">
            <div class="container">
              <h2>Наша экспертиза</h2>
              <div class="partner-wrapper">
                <div class="partner-slider">
                  <div class="slider resourses clearfix">
                    <div class="slider-container clearfix">
                      <div class="slide-list clearfix">
                        <ul
                          id="slide-wrap-lic"
                          class="slide-wrap-lic col-md-12 clearfix"
                        >
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_01.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Лицензия ФСТЭК на деятельность по технической защите
                                конфиденциальной информации Рег. № 3077 от 21.10.2016г.2
                              </span>
                            </div>
                          </li>
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_02.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Лицензия ФСТЭК на деятельность по разработке
                                и производству средств защиты конфиденциальной
                                информации Рег. № 1639 от 21.10.2016г.3
                              </span>
                            </div>
                          </li>
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_03.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Лицензия ФСБ ЛСЗ № 0015178 Рег. № 16515 Н от 27.03.2018г.
                                единая (п. 2, 3, 8, 9, 11, 12,
                                13, 14, 15, 17, 18, 20, 21, 22, 23,
                                24, 25, 26, 27, 28)</span>
                            </div>
                          </li>
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_04.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Свидетельство о регистрации программы для ЭВМ КСК.СИУМВВ
                              </span>
                            </div>
                          </li>
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_05.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Свидетельство о регистрации программы для ЭВМ КСК.ГАСУ
                              </span>
                            </div>
                          </li>
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_06.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Свидетельство о государственной
                                регистрации программы для ЭВМ КСК.ГИС ГМП
                              </span>
                            </div>
                          </li>
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_07.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Свидетельство о государственной
                                регистрации программы для ЭВМ КСК.МФЦ
                              </span>
                            </div>
                          </li>
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_08.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Свидетельство о государственной
                                регистрации программы для ЭВМ КСК.Документооборот
                              </span>
                            </div>
                          </li>
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_09.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Свидетельство о государственной
                                регистрации программы для ЭВМ КСК.Шлюз СМЭВ 3
                              </span>
                            </div>
                          </li>
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_010.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Свидетельство о государственной
                                регистрации программы для
                                ЭВМ КСК.Платформа Интеллектуальный конвейер
                              </span>
                            </div>
                          </li>
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_011.svg"
                                alt="partner"
                              />
                              <span class="partner-title">
                                Свидетельство о
                                государственной регистрации программы для ЭВМ КСК.Госэкспертиза
                              </span>
                            </div>
                          </li>
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_012.svg"
                                alt="partner"
                              />
                              <span class="partner-title">Свидетельство
                                о регистрации программы для ЭВМ КСК.Стройнадзор</span>
                            </div>
                          </li>
                          <li class="slide-item-lic">
                            <div class="slide-block"><img
                                src="@/assets/img/about-page/Licenziya_013.svg"
                                alt="partner"
                              />
                              <span class="partner-title">Свидетельство о
                                государственной регистрации
                                программы для ЭВМ Сервис деск КСК.SD
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <button class="navy next-slide-lic"></button>
                      <button class="navy prev-slide-lic"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import GoBackBtn from '@/components/common/GoBackBtn.vue';

export default {
  name: 'AboutPage',
  components: { GoBackBtn },
  mounted() {
    this.bullSlider();
    this.resoursesSlider();
    this.licensesSlider();
  },

  methods: {
    bullSlider() {
      const changeBTime = 5000;
      const transitionS = 0;
      const inS = 200;
      const bulletInfo = $('.second-info-tabs-section-wrapper section');
      const bulletItem = $('.second-btn-toogle');
      const listBul = bulletItem.length;
      let currentBul;
      let changeTimeoutBul;
      function moveToBul(newBull) {
        let idxBull = newBull;
        if (newBull === 'prevBull') {
          idxBull = currentBul > 0 ? currentBul - 1 : listBul - 1;
        }
        if (newBull === 'nextBull') {
          idxBull = currentBul < listBul - 1 ? currentBul + 1 : 0;
        }
        bulletItem.removeClass('active').eq(idxBull).addClass('active');
        bulletInfo
          .fadeOut(transitionS)
          .removeClass('active')
          .eq(idxBull)
          .addClass('active')
          .fadeIn(inS);
        currentBul = idxBull;
        clearTimeout(changeTimeoutBul);
        changeTimeoutBul = setTimeout(() => {
          moveToBul('nextBull');
        }, changeBTime);
      }
      // $('.second-tabs-wrapper .second-btn-toogle').click(() => {
      //   const idxBull = $('.second-tabs-wrapper .second-btn-toogle').index(this);
      //   console.log('1111', this);
      //   moveToBul(idxBull);
      // });
      $('.second-tabs-wrapper .second-btn-toogle').click(() => {
        const idxBull = $('.second-tabs-wrapper .second-btn-toogle').index(this);
        moveToBul(idxBull);
      });
      moveToBul('nextBull');
    },

    resoursesSlider() {
      const e = $('.slider .slide-wrap');
      const i = $('.next-slide');
      const t = $('.prev-slide');
      let s = !1;
      let n = !1;
      const l = $('.slide-wrap .slide-item').outerWidth();
      // eslint-disable-next-line no-unused-expressions, no-sequences
      $('.slide-wrap .slide-item a'), $('.slide-wrap .slide-item img');
      if (
        (i.click(() => {
          // eslint-disable-next-line no-unused-expressions
          !1 === s
            && ((s = !0),
            e.animate({ left: e.position().left - l }, 500, () => {
              // eslint-disable-next-line no-unused-expressions, no-sequences
              e.find('.slide-item:first').appendTo(e).parent().css({ left: 0 }), (s = !1);
            }));
        }),
        t.click(() => {
          // eslint-disable-next-line no-unused-expressions
          !1 === n
            && ((n = !0),
            e
              .css({ left: e.position().left - l })
              .find('.slide-item:last')
              .prependTo(e)
              .parent(),
            e.animate({ left: 0 }, 500, () => {
              n = !1;
            }));
        }),
        $('.slide-wrap .slide-item.selected'))
      ) {
        const o = e.find('.slide-item.selected');
        // eslint-disable-next-line no-unused-expressions, no-sequences
        e.find('.slide-item.selected').nextAll('li').prependTo(e),
        o.prependTo(e).parent().animate({ left: 0 }, 500);
      }
    },

    licensesSlider() {
      const e = $('.slide-wrap-lic');
      const i = $('.next-slide-lic');
      const t = $('.prev-slide-lic');
      let s = !1;
      let n = !1;
      const l = $('.slide-wrap-lic .slide-item-lic').outerWidth();
      // eslint-disable-next-line no-unused-expressions, no-sequences
      $('.slide-wrap-lic .slide-item-lic a'), $('.slide-wrap-lic .slide-item-lic img');
      if (
        (i.click(() => {
          // eslint-disable-next-line no-unused-expressions
          !1 === s
            && ((s = !0),
            e.animate({ left: e.position().left - l }, 500, () => {
              // eslint-disable-next-line no-unused-expressions, no-sequences
              e.find('.slide-item-lic:first').appendTo(e).parent().css({ left: 0 }), (s = !1);
            }));
        }),
        t.click(() => {
          // eslint-disable-next-line no-unused-expressions
          !1 === n
            && ((n = !0),
            e
              .css({ left: e.position().left - l })
              .find('.slide-item-lic:last')
              .prependTo(e)
              .parent(),
            e.animate({ left: 0 }, 500, () => {
              n = !1;
            }));
        }),
        $('.slide-wrap-lic .slide-item-lic.selected'))
      ) {
        const o = e.find('.slide-item-lic.selected');
        // eslint-disable-next-line no-unused-expressions, no-sequences
        e.find('.slide-item-lic.selected').nextAll('li').prependTo(e),
        o.prependTo(e).parent().animate({ left: 0 }, 500);
      }
    },
  },
};
</script>
