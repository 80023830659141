<template>
  <div class="top-bar--item top-bar--item-locale">
    <div class="locale">
      <div class="locale__btn">
        <button
          class="locale__btn-globe pe-0"
          :title="getGlobeTitle"
          @click="changeLocale"
        >
          <i class="btn-icon icon_size_l icon-globe"></i>
        </button>
        <button
          class="locale__btn-arrow ps-0"
          @click="showSelectors"
        >
          <i class="btn-icon icon_size_xs icon-caret-down"></i>
        </button>
      </div>
      <div
        v-if="isSelectorsShown"
        v-on-clickaway="closeSelectors"
        class="locale__body submenu"
      >
        <div class="mb-2">
          <div class="frm-label">
            {{ getMessageByKey('locale.language') }}
          </div>
          <selector
            :params="{
                placeHolderTitle: getMessageByKey('local.language.placeholder'),
                inputDeleteIcon: false,
                droplistOverlay: true,
                droplistAdaptive: true,
              }"
            :values="selectedParameter['language']"
            :handbook="handbookData['language']"
            @newValues="onLanguageSelect"
          ></selector>
        </div>

        <div>
          <div class="frm-label">
            {{ getMessageByKey('locale.region') }}
          </div>
          <selector
            :params="{
                placeHolderTitle: getMessageByKey('local.region.placeholder'),
                inputDeleteIcon: false,
                droplistOverlay: true,
                droplistAdaptive: true,
              }"
            :values="selectedParameter['region']"
            :handbook="handbookData['region']"
            @newValues="onRegionSelect"
          ></selector>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Selector from '@kck/sd-ui-kit/src/components/selector/Selector.vue';
import { mixin as onClickaway } from 'vue-clickaway';
import _ from 'lodash';

export default {
  name: 'LocaleBar',

  mixins: [onClickaway],

  components: {
    Selector,
  },

  data() {
    return {
      isSelectorsShown: false,
      selectedParameter: {
        language: [],
        region: [],
      },
      handbookData: {
        language: {
          list: [],
        },
        region: {
          list: [],
        },
      },
    };
  },

  computed: {
    getGlobeTitle() {
      return `${this.selectedParameter.language?.at(0)?.label} - ${this.selectedParameter.region?.at(0)?.label}`;
    },

    isLangRotation() {
      return this.lcLanguages.length < 4;
    },

    locale() {
      const locale = this.$root.locale.match(/(.*)_(.*)$/);
      return {
        language: locale?.at(1),
        region: locale?.at(2),
      };
    },

    lcLanguages() {
      return this.$root.settings.system.lcLanguages;
    },

    lcRegions() {
      return this.$root.settings.system.lcRegions;
    },
  },

  mounted() {
    this.handbookData.language.list = this.lcLanguages.map((value) => ({
      value: value.value,
      label: value.label,
    }));
    this.selectedParameter.language = this.findHandbookData('language', this.locale.language);

    this.handbookData.region.list = this.lcRegions.map((value) => ({
      value: value.value,
      label: value.label,
    }));
    this.selectedParameter.region = this.findHandbookData('region', this.locale.region);
  },

  methods: {
    showSelectors() {
      this.isSelectorsShown = !this.isSelectorsShown;
    },

    closeSelectors() {
      this.isSelectorsShown = false;
    },

    onLanguageSelect(data) {
      this.selectedParameter.language = data;
      const value = data?.at(0)?.value;
      const currentRegion = this.selectedParameter.region?.at(0)?.value;
      const foundDefaultRegion = this.$root.settings.system.lcLanguages
        .find((el) => el.value === value)?.defaultRegion;
      if (foundDefaultRegion) {
        this.selectedParameter.region = [{
          value: foundDefaultRegion,
          label: foundDefaultRegion,
        }];
      }

      this.setLocale(value, foundDefaultRegion || currentRegion);
    },

    onRegionSelect(data) {
      this.selectedParameter.region = data;
      const value = data?.at(0)?.value;
      const currentLanguage = this.selectedParameter.language?.at(0)?.value;

      this.setLocale(currentLanguage, value);
    },

    findHandbookData(handbook, handbookValue) {
      const value = _.find(this.handbookData[handbook].list, (el) => el.value === handbookValue);
      return value ? [value] : [];
    },

    changeLocale() {
      if (!this.isLangRotation) return;
      const localeIndex = this.lcLanguages
        .findIndex((el) => el.value === this.selectedParameter.language?.at(0)?.value);
      const localeNextIndex = localeIndex + 1 > Math.min(this.lcLanguages.length - 1, 2)
        ? 0 : localeIndex + 1;
      this.selectedParameter.language = [{
        label: this.lcLanguages[localeNextIndex]?.label,
        value: this.lcLanguages[localeNextIndex]?.value,
      }];

      this.setLocale(
        this.lcLanguages[localeNextIndex]?.value,
        this.selectedParameter.region?.at(0)?.value,
      );
    },

    setLocale(language, region) {
      window.dispatchEvent(new CustomEvent('setLocale', {
        detail: { culture: `${language}_${region}` },
      }));
    },
  },
};
</script>
