const getLocaleValue = (system) => {
  let locale = '';

  // query
  const urlSearchParams = new URLSearchParams(window.location.href.match(/\?(.+)?$/)?.at(1));
  const entries = Object.fromEntries(urlSearchParams.entries());
  locale = entries.locale;

  // cookie
  if (!locale) {
    const getCookie = (name) => {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return '';
    };

    locale = getCookie('LOCALE');
  }

  // browser language
  if (!locale) {
    locale = navigator.language;
  }

  // default locale
  if (!locale) {
    locale = system.locale;
  }

  const localeRegex = locale.match(/(.*)[_-](.*)$/);
  const localeRegexLang = localeRegex?.at(1)?.toLowerCase() || locale?.toLowerCase();
  const localeRegexRegion = localeRegex?.at(2)?.toUpperCase();

  const systemLocaleRegex = system.locale.match(/(.*)_(.*)$/);
  const systemLocaleRegexLang = systemLocaleRegex?.at(1)?.toLowerCase();
  const systemLocaleRegexRegion = systemLocaleRegex?.at(2)?.toUpperCase();

  const localeLanguage = system.lcLanguages
    .find((el) => el.value === localeRegexLang)?.value || systemLocaleRegexLang;
  const localeRegion = system.lcRegions.find((el) => el.value === localeRegexRegion)?.value
    || system.lcLanguages.find((el) => el.value === localeRegexLang)?.defaultRegion
    || systemLocaleRegexRegion;
  locale = `${localeLanguage}_${localeRegion}`;

  return locale;
};

const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

export {
  setCookie,
  getLocaleValue,
};
