import _ from 'lodash';
import Vue from 'vue';
import commonFunctionsMixin from '@kck/mixin-collection/src/mixins/commonFunctionsMixin';
import messageFunctionsMixin from '@kck/mixin-collection/src/mixins/messageFunctionsMixin';
import { setLocale } from '@kck/locale/src/js/localeStore';
// eslint-disable-next-line no-unused-vars
import EventBus from '@/event-bus';
import {
  getMessagesBundles,
  getCurrentMessageBundle,
} from '@kck/mixin-collection/src/js/importMessagesBundles';
import { getJsonFileByPath } from '@/helpers/get-json-file';
import paths from '@/paths';
import { setCookie, getLocaleValue } from './helpers/localeHelper';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

Vue.mixin(commonFunctionsMixin);
Vue.mixin(messageFunctionsMixin);

const availableLanguages = (process.env.VUE_APP_SUPPORTS_LANGUAGE || '').split(',')
  .filter(Boolean);
const defaultLanguage = (process.env.VUE_APP_DEFAULT_LANGUAGE || '');

const messagesBundles = getMessagesBundles({
  requireMessagesBundles: require.context('./messages', false, /\.properties$/i),
  availableLanguages,
  defaultLanguage,
});

let locale = 'default';
let system = {};
let messages = {
  ...getCurrentMessageBundle({
    messagesBundles,
    locale,
  }),
};
const vue = new Vue({
  router,
  data: {
    messages,
    locale,
    config: {},
    settings: {
      system: {
        lcLanguages: [],
        lcRegions: [],
      },
    },
  },
  render: (h) => h(App),
});

Promise.all([
  getJsonFileByPath(paths.systemConfig),
  getJsonFileByPath(paths.mainPageConfig),
]).then((result) => {
  system = result?.at(0) || {};

  vue.$root.$data.config = result?.at(1) || {};
  vue.$root.$data.settings.system.lcLanguages = system.lcLanguages;
  vue.$root.$data.settings.system.lcRegions = system.lcRegions;

  locale = getLocaleValue(system);
  setLocale(locale);
  vue.$root.$data.locale = locale;

  vue.$root.$data.messages = {
    ...getCurrentMessageBundle({
      messagesBundles,
      locale,
    }),
  };

  window.addEventListener('setLocale', (event) => {
    const culture = _.get(event, 'detail.culture');

    setCookie('LOCALE', culture, 120);
    setLocale(culture);
    const newMessages = {
      ...getCurrentMessageBundle({
        messagesBundles,
        locale: culture,
      }),
    };
    messages = newMessages;
    // eslint-disable-next-line no-param-reassign
    vue.$root.$data.messages = newMessages;
    // eslint-disable-next-line no-param-reassign
    vue.$root.$data.locale = culture;
  });
  vue.$mount('#app');
})
  .catch(() => {
    vue.$mount('#app');
  });
