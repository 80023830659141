<template>
  <div class="spoiler" :class="{ 'spoiler-open': isOpen }">
    <div
      class="spoiler__header"
      role="button"
      tabindex="0"
      @keydown="toggle"
      @click="toggle">
      <div>
        <slot></slot>
      </div>
      <div class="spoiler__title">{{ title }}</div>
      <button class="ml-auto">
        <img v-if="!isOpen" src="@/assets/img/icons/spoiler-icon.svg" alt="">
        <img v-else src="@/assets/img/icons/spoiler-icon-open.svg" alt="">
      </button>
    </div>
    <div>
      <div></div>
      <transition name="fade">
        <div class="spoiler__content" v-if="isOpen">{{ content }}</div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonSpoiler',

  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
