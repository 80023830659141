<template>
  <div id="app">
    <header-component/>
    <transition name="fade">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import HeaderComponent from '@/components/common/HeaderComponent.vue';

export default {
  components: {
    HeaderComponent,
  },
};
</script>

<style lang="scss">
@import "src/scss/style";
</style>
