export default {
  computed: {
    getUrl() {
      return (path) => {
        let newPath = path;
        const params = this.$route.query;
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const param in params) {
          newPath += `&${param}=${params[param]}`;
        }
        return newPath;
      };
    },
  },
};
