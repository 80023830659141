<template>
  <div class="main-page-visual">
    <div class="main-page-visual__inner main-page-visual__inner_st">
      <img src="@/assets/img/products/st/visual/st-main.png" alt="KCK.Service and Teamwork">
    </div>
  </div>
</template>

<script>
export default {
  name: 'STPageVisual',
};
</script>
