<template>
  <div
    class="header"
    v-click-outside="externalClick"
  >
    <router-link
      to="/"
      class="header__logo"
    >
      <img
        src="@/assets/img/kck-logo.png"
        alt="KCK"
      >
    </router-link>
    <div class="header__right-block">
      <ul :class="['menu-nav', { 'menu-nav_open': isMobileMenuOpen }]">
        <li>
          <router-link to="/about">
            <span>
              О компании
            </span>
          </router-link>
        </li>
        <li>
          <router-link to="/contacts">
            <span>
              Контакты
            </span>
          </router-link>
        </li>
      </ul>
      <div class="custom mob-menu">
        <p><button
            :class="['btn btn-navbar btn-menu', { 'btn-menu_open': isMobileMenuOpen }]"
            @click="openMobileMenu"
          ></button></p>
      </div>
      <div class="header__short-info">
        <div class="header__short-info-item">
          <img
            src="@/assets/img/icons/phone.svg"
            alt="Телефон"
          >
          <a
            href="tel:+74952801123"
            class="header__phone"
          >+7 (495) 280-11-23</a>
        </div>
        <div class="header__short-info-item">
          <img
            src="@/assets/img/icons/calendar.svg"
            alt="Календарь"
            class="mr-6px"
          >
          <span>Пн - Пт: 9:00 - 19:00</span>
        </div>
      </div>
      <locale-bar
        v-if="showLocaleBar"
      ></locale-bar>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import LocaleBar from './header/LocaleBar.vue';

export default {
  name: 'HeaderComponent',

  components: {
    LocaleBar,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      isMobileMenuOpen: false,
    };
  },

  watch: {
    $route() {
      this.isMobileMenuOpen = false;
    },
  },

  computed: {
    showLocaleBar() {
      return this.$root.config?.showLocaleBar;
    },
  },

  methods: {
    openMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },

    externalClick() {
      this.isMobileMenuOpen = false;
    },
  },
};
</script>
