<template>
  <div class="main-page-content">
    <div class="main-page-content__inner">
      <go-back-btn
        class="go-back-product-page"
      />
      <div class="main-page-content__mobile-header">
        <div>
          <img
            src="@/assets/img/products/triangle-st.svg"
            alt=""
          >
        </div>
        <div>КСК.Проекты</div>
      </div>
      <h1>КСК.Проекты — процессная система для управления продуктами и проектами на
        протяжении всего жизненного цикла — от идеи до вывода из эксплуатации</h1>
      <div class="mobile-btn">
        <a class="btn-try-for-free" href="/projects/#/?backPath=/projects">
          <button class="btn btn--common">
            Попробовать бесплатно
          </button>
        </a>
      </div>
      <div class="main-text">
        Грамотное управление продуктами и проектами по их созданию позволяет Вам не только
        распределять задачи и ресурсы, но и добиваться глобальных стратегических целей Вашей
        компании. Повышайте эффективность бизнеса с КСК.Проекты.
      </div>
      <h3>Комплексное решение для управления продуктами и проектами:</h3>
      <div class="main-page-advantages-mobile">
        <common-spoiler
          title="Продукты"
          content="управление жизненным циклом от идеи, проектирования
          и производства до продажи, обслуживания и вывода с рынка"
        >
          <img src="@/assets/img/products/projects/advantages/Products.png" alt="">
        </common-spoiler>
        <common-spoiler
          title="Проекты"
          content="процессное управление проектами любого уровня сложности,
            полный контроль и прозрачность выполнения"
        >
          <img src="@/assets/img/products/projects/advantages/Chart.png" alt="">
        </common-spoiler>
        <common-spoiler
          title="Портал системы"
          content="единое пространство для общения, хранения
            и обмена документами"
        >
          <img src="@/assets/img/products/projects/advantages/Info.png" alt="">
        </common-spoiler>
        <common-spoiler
          title="Аналитика"
          content="полный контроль по исполнению задач
            сотрудниками и отчетность в удобном формате"
        >
          <img src="@/assets/img/products/projects/advantages/Graph.png" alt="">
        </common-spoiler>
      </div>
      <div class="main-page-advantages">
        <div class="main-page-advantage">
          <img src="@/assets/img/products/projects/advantages/Products.png" alt="Продукты">
          <span>
            <strong>Продукты</strong> — управление жизненным циклом от идеи, проектирования и
            производства до продажи, обслуживания и вывода с рынка
          </span>
        </div>
        <div class="main-page-advantage">
          <img src="@/assets/img/products/projects/advantages/Chart.png" alt="Проекты">
          <span>
            <strong>Проекты</strong> — процессное управление проектами любого уровня сложности,
            полный контроль и прозрачность выполнения
          </span>
        </div>
        <div class="main-page-advantage">
          <img src="@/assets/img/products/projects/advantages/Info.png" alt="Портал системы">
          <span>
            <strong>Портал системы</strong> — единое пространство для общения, хранения
            и обмена документами
          </span>
        </div>
        <div class="main-page-advantage">
          <img src="@/assets/img/products/projects/advantages/Graph.png" alt="График">
          <span>
            <strong>Аналитика</strong> — полный контроль по исполнению задач
            сотрудниками и отчетность в удобном формате
          </span>
        </div>
      </div>
      <div class="main-page-buttons">
        <a class="btn-about" href="https://www.kck.ru/products/kck-project" target="_blank">
          <button class="btn btn--common">
            Узнать больше
          </button>
        </a>
        <a class="btn-try-for-free" :href="getUrl('/projects/#/?backPath=/projects')">
          <button class="btn btn--common">
            Попробовать бесплатно
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import GoBackBtn from '@/components/common/GoBackBtn.vue';
import CommonSpoiler from '@/components/common/CommonSpoiler.vue';
import redirectMixin from '@/mixins/redirectMixin';

export default {
  name: 'ProjectsPageContent',

  components: {
    GoBackBtn,
    CommonSpoiler,
  },

  mixins: [
    redirectMixin,
  ],
};
</script>
