<template>
  <div>
    <div class="main-page">
      <ProjectsPageContent/>
      <ProjectsPageVisual/>
    </div>
  </div>
</template>

<script>
import ProjectsPageContent from '@/components/product-page-projects-parts/ProjectsPageContent.vue';
import ProjectsPageVisual from '@/components/product-page-projects-parts/ProjectsPageVisual.vue';

export default {
  name: 'ProductPageProjects',
  components: {
    ProjectsPageVisual,
    ProjectsPageContent,
  },
};
</script>
